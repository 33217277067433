import React from 'react';

class EventLocation extends React.Component {

  renderPosition(coord) {
    const position = this.props.data[coord];
    const prettyPosition = `${position.toFixed(3)}°`;
    /*const commaElem = (coord == "latitude") ?
      <span style={{position: "relative", top: "7px", left: "10px"}} className="d-inline d-sm-none">,</span> : null;*/
    return (
      <div className="mb-2">
        <div className="h5 mb-0">{prettyPosition}</div>
        <label className="text-uppercase border-top text-muted">{coord}</label>
      </div>
    );
  }

  createMapHref() {
    const latitude = this.props.data['latitude'].toFixed(6);
    const longitude = this.props.data['longitude'].toFixed(6);
    return `https://maps.google.com/maps?t=k&q=${latitude},${longitude}`;
  }

  render() {

    return (
      <div className="text-center">

        <div className="mb-4 py-1 border-light border-bottom border-top">
          Event Location
        </div>

        <div className="row justify-content-center">

          <div className="col-3 col-md-2 col-lg-12 p-0">
            {this.renderPosition("latitude")}
          </div>

          <div className="col-4 md-2 col-lg-12 p-0">
            {this.renderPosition("longitude")}
          </div>

          <div className="col-5 col-md-2 col-lg-12 mt-0 mt-lg-3 text-right text-md-center">
            <a className="btn btn-outline-light"
                    href={this.createMapHref()}
                    target="_blank" rel="noopener noreferrer">
              <img style={{width: '25px', height: 'auto'}} className="d-inline-block" src="img/icon/map.svg" alt="Map event location"/>
              <span className="ml-1 text-dark">Map</span>
              </a>
          </div>

        </div>

      </div>
    );
  }
}

export default EventLocation;
