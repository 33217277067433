import React from 'react';
import {geolocated} from 'react-geolocated';

class Geolocation extends React.Component {

  render() {
    let geoAvailElem = this.props.isGeolocationAvailable ? 'YES': 'NO';
    let geoEnabElem = this.props.isGeolocationEnabled ? 'YES' : 'NO';

    let coordsElem;
    if (this.props.coords) {
      coordsElem = <p>coords: {this.props.coords.latitude}, {this.props.coords.longitude}</p>;
    }

    return (
      <div className="new-event my-4">
        <p>GEO Available:{geoAvailElem} | Enabled: {geoEnabElem}</p>
        {coordsElem}
      </div>
    )
  }

}

export default geolocated({
  positionOptions: {
    timeout: 10000
  }
})(Geolocation);
