import React from 'react';


class Header extends React.Component {

  render() {
  
    return (
      <header className="p-2 mb-4 mb-lg-5 shadow-sm">

        <img src="img/main-brand.png"
            style={{width: '36px', height: 'auto'}}
            alt="Coast Clouds" />

        <h1 className="h3 h-100 ml-2 mb-0 align-middle d-inline-block">Coast Clouds</h1>

      </header>
    );
  
  }


}
 


export default Header;
