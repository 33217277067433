import React from 'react';
import Config from './Config.js';
import './Event.css';


class EventPhoto extends React.Component {
  constructor(props) {
    super(props);
    this.photoImage = new Image();
    this.retryCount = 0;
    this.retryMaxCount = 10;
    this.retryWaitTime = 500;
    this.state = {
      isLoaded: false,
      isError: false
    }
  }


  componentDidMount() {
    this.initLoadingPhoto();
  }


  componentWillUnmount = () => {
    this.deInitLoadingPhoto();
  }


  initLoadingPhoto = () => {
    this.photoImage.src = this.createPhotoUrl();

    this.photoImage.onload = () => {
      this.setState({isLoaded: true});
      this.props.onLoadingComplete();
    }

    this.photoImage.onerror = () => {
      if (this.props.isNew && (this.retryCount <= this.retryMaxCount)) {
        window.setTimeout(() => {
          this.photoImage.src = this.createPhotoUrl();
        }, this.retryWaitTime);
        this.retryCount += 1;
      } else {
        this.setState({isLoaded: true, isError: true});
        this.props.onLoadingComplete();
      }
    }
  }


  deInitLoadingPhoto = () => {
    this.photoImage.src = '';
    this.photoImage.onload = null;
    this.photoImage.onerror = null;
  }


  createPhotoUrl() {
    const rootDomain = Config.S3_SITE_ROOT;
    const baseUrl = '/event/' + this.props.data.id + '/ground_orig.jpg';
    return `${rootDomain}${baseUrl}`;
  }


  render() {
    const { isLoaded, isError } = this.state;
    
    let elem;
    if (isLoaded && !isError) {
        elem = <img src={this.photoImage.src}
                    className="mb-2 border border-secondary"
                    alt="Clouds at event.ISODate() from the ground" />;
    } else if (isLoaded && isError) {
      elem = <div className="text-center">Error loading photo.</div>;
    } else {
      elem = <div className="text-center">Loading...</div>;
    }

    const hasBody = this.props.data.body.trim().length > 0;
    let eventBodyElem = hasBody ? <div>{this.props.data.body}</div> : <div>Ground view</div>;

    return (
      <div className="event-photo h-100 p-3 shadow-sm border border-light">
        {elem}
        {eventBodyElem}
      </div>
    );

  }

}

export default EventPhoto;
