import React from 'react';
import Api from './Api.js';
import Event from './Event.js';


const DAYS_DELTA = 5;
const TIME_DELTA = DAYS_DELTA*24*60*60;


class Events extends React.Component {
  constructor(props) {
    super(props);
    this.eventsRef = React.createRef();
    this.eventsFetched = [];
    this.eventsLoaded = [];
    this.currentEndTime = Math.floor(Date.now() / 1000);
    this.currentStartTime = Math.floor(this.currentEndTime - TIME_DELTA);
    this.isNewEventLoading = false;
    this.state = {
      eventDataLoading: null,
      isLoaded: true
    };
  }


  componentDidMount() {
    this.fetchEvents();
    window.addEventListener('scroll', this.onScroll, false);
  }


  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
  }


  componentWillReceiveProps(props) {
    if (props.newEventData) {
      this.isNewEventLoading = true;
      this.setState({eventDataLoading: props.newEventData});
    }
  }


  onScroll = () => {
    const preBottomPadding = 100;
    const currentScrollPos = window.innerHeight + window.scrollY;
    if (currentScrollPos > document.body.offsetHeight - preBottomPadding) {
        this.fetchEvents();
      }
  }


  fillEntireScreenWithEvents = () => {
    const isAtTop = document.documentElement.scrollTop === 0;
    const isEventsNotFilled = this.eventsRef.current.offsetHeight < window.innerHeight;
    if (isAtTop && isEventsNotFilled) {
      this.fetchEvents();
    }
  }


  fetchEvents = () => {
    if (!this.state.isLoaded || this.eventsFetched.length) return;
    this.setState({isLoaded: false});
    const apiPath = `events?start=${this.currentStartTime}&end=${this.currentEndTime}`;
    Api.get(apiPath).then(resp => {
      this.eventsFetched = this.eventsFetched.concat(resp.data);
      this.setState({isLoaded: true});
      this.currentEndTime -= TIME_DELTA;
      this.currentStartTime -= TIME_DELTA;
      this.loadNextEvent();
    });
  }

  loadNextEvent = () => {
      if (this.eventsFetched.length) {
        const eventDataLoading = this.eventsFetched.shift();
        this.setState({eventDataLoading: eventDataLoading});
      } else {
        this.setState({eventDataLoading: null});
        this.fillEntireScreenWithEvents();
      }
  }


  onEventLoadingComplete = () => {
    if (this.state.eventDataLoading) {
      if (this.isNewEventLoading) {
        this.eventsLoaded.unshift(this.state.eventDataLoading);
        this.isNewEventLoading = false;
      } else {
        this.eventsLoaded.push(this.state.eventDataLoading);
      }
    }
    this.loadNextEvent();
  }


  render() {
    const { eventDataLoading, isLoaded } = this.state;

    let eventDataLoadingElem;
    if (eventDataLoading) {
      eventDataLoadingElem = <Event data={eventDataLoading}
                                    key={eventDataLoading.id}
                                    isNew={this.isNewEventLoading}
                                    onEventLoadingComplete={this.onEventLoadingComplete} />
    }

    let eventsLoadedElem;
    if (this.eventsLoaded.length) {
      eventsLoadedElem = <div>{this.eventsLoaded.map(data => <Event data={data} key={data.id} />)} </div>;
    }

    let loadingMessageElem;
    if (!isLoaded) {
      loadingMessageElem = <div className="text-center">Loading...</div>;
    }

    return (
      <div ref={this.eventsRef} className="events my-4">
        {eventsLoadedElem}
        {eventDataLoadingElem}
        {loadingMessageElem}
      </div>
    );
  }

}

export default Events;
