import React from 'react';
import Header from './Header.js'
import Events from './Events.js'
import NewEvent from './NewEvent.js'


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newEventData: null
    };
  }


  onNewEventCreated = (newEventData) => {
    this.setState({newEventData: newEventData});
  }


  render() {
    let newEventElem;
    if (true) {
      newEventElem = <NewEvent onNewEventCreated={this.onNewEventCreated} />;
    }

    return (
      <> 
      <Header />

      <div className="container my-4">

        {newEventElem}

        <Events newEventData={this.state.newEventData}/>

      </div>
      </>
    );
  }
}

export default App;
