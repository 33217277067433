import React from 'react';
import axios from 'axios';
import Api from './Api.js';
import Config from './Config.js';
import Geolocation from './Geolocation.js';


class NewEvent extends React.Component {
  constructor(props) {
    super(props);
    this.photoFileInputRef = React.createRef();
    this.positionCoords = null;
    this.newEventData = null;
    this.state = {
      newEventPhotoImage: null,
      newEventFormData: {
        title: '',
        body: '',
        name_creator_provided: window.localStorage.getItem('NewEvent.name') || ''
      },
      progressMessage: null,
      isFetchingGeolocation: false,
      fileUploadPercentComplete: null
    };
  }


  startNewEventCreation = () => {
    this.photoFileInputRef.current.click();
  }


  onGeolocationSuccess = (position) => {
    this.positionCoords = position.coords;
    this.setState({progressMessage: "Starting photo upload..."});
    this.initEventCreation();
  }


  onGeolocationError = (error) => {
    window.setTimeout(() => {
      let errorMessage = "Error getting your GPS location!";
      if (error) {
        if (error.code) errorMessage += `\n\ncode: ${error.code}`;
        if (error.message) errorMessage += `\nmessage: ${error.message}`;
      }
      alert(errorMessage);
      this.cancelNewEventCreation(false);
    }, 300);
  }


  initEventCreation = () => {
    const data = {latitude: this.positionCoords.latitude,
                  longitude: this.positionCoords.longitude};
    Api.post('events', data)
      .then(resp => {
        this.newEventData = resp.data;
        this.uploadEventPhoto();
      })
      .catch(err => { alert(err); });
  }


  uploadEventPhoto = () => {
    const photoFile = this.photoFileInputRef.current.files[0];

    let data = new window.FormData();
    data.append('key', `web/${this.newEventData.id}.jpg`);
    data.append('filename', `web/${this.newEventData.id}.jpg`);
    data.append('acl', 'public-read');
    data.append('Content-Type', photoFile.type);
    data.append('file', photoFile);

    axios.post(Config.S3_UPLOAD_ROOT, data, {onUploadProgress: this.onUploadProgress})
      .catch(err => { alert(err); });
  }


  onUploadProgress = (progress) => {
    const fileUploadPercentComplete = Math.round((progress.loaded * 100) / progress.total);
    if (fileUploadPercentComplete === 100) {
      this.setState({progressMessage: null});
    } else {
      const progressMessage = `Uploading photo (${fileUploadPercentComplete}%)...`;
      this.setState({progressMessage: progressMessage});
    }
  }


  completeEventCreation = () => {
    this.setState({progressMessage: "Saving post data..."});
    const formData = this.state.newEventFormData;
    // backend dis-allows empty strings in value updates:
    const updateData = {title: formData.title || ' ',
                  body: formData.body || ' ',
                  name_creator_provided: formData.name_creator_provided || ' '};
    Api.put(`events/${this.newEventData.id}`, updateData)
      .then(resp => {
        this.setState({progressMessage: "New post complete!"});
        window.setTimeout(() => {
          const completedNewEventData = {...this.newEventData, ...updateData};
          this.props.onNewEventCreated(completedNewEventData);
          this.setState({newEventPhotoImage: null});
        }, 300);
      })
      .catch(err => { alert(err); });
  }


  handlePhotoFileInputChange = (evt) => {
    const photoFileCurrent = this.photoFileInputRef.current;
    if (photoFileCurrent.files && photoFileCurrent.files.length) {
      const photoFile = photoFileCurrent.files[0];
      this.setState({newEventPhotoImage: photoFile,
                     isFetchingGeolocation: true,
                     progressMessage: "Finding your location..."});
    }
  }


  handleTitleChange = (evt) => {
    const newEventFormData = {...this.state.newEventFormData, title: evt.target.value};
    this.setState({newEventFormData: newEventFormData});
  }


  handleBodyChange = (evt) => {
    const newEventFormData = {...this.state.newEventFormData, body: evt.target.value};
    this.setState({newEventFormData: newEventFormData});
  }


  handleNameChange = (evt) => {
    const name = evt.target.value;
    const newEventFormData = {...this.state.newEventFormData, name_creator_provided: name};
    this.setState({newEventFormData: newEventFormData});
    window.localStorage.setItem('NewEvent.name', name);
  }


  cancelNewEventCreation = (confirmCancel = true) => {
    let shouldCancel = true;
    if (confirmCancel) {
      shouldCancel = window.confirm("Sure you want to cancel? (No Undo!)");
    }
    if (shouldCancel) {
      if (confirmCancel && this.newEventData) {
        // delete 'in progress' event data:
        Api.delete(`events/${this.newEventData.id}`).then(resp => {
          this.setState({newEventPhotoImage: null});
        });
      } else {
        this.setState({newEventPhotoImage: null});
      }
    }
  }


  render() {
    let newEventCreateButtonElem;
    if (!this.state.newEventPhotoImage) {
      newEventCreateButtonElem =
        <div className="text-center">
          <button className="btn btn-primary shadow" disabled={false} onClick={this.startNewEventCreation}>
            <div className="h3 m-0 p-1">Post a cloud photo now!</div>
          </button>
        </div>
    }

    let newEventPhotoElem;
    if (this.state.newEventPhotoImage) {
      const imgSrc = window.URL.createObjectURL(this.state.newEventPhotoImage);
      newEventPhotoElem = <img src={imgSrc} alt=''/>
    }

    let geoElem;
    if (this.state.isFetchingGeolocation) {
      geoElem = <Geolocation onSuccess={this.onGeolocationSuccess} onError={this.onGeolocationError} />;
    }

    let submitButtonElem;
    if (this.state.progressMessage) {
      submitButtonElem = <button className="btn btn-primary" disabled>{this.state.progressMessage}</button>
    } else {
      submitButtonElem = <button className="btn btn-primary" onClick={this.completeEventCreation}>Save &amp; Complete</button>
    }

    return (
      <>
        {newEventCreateButtonElem}

      <div className={"new-event " + (this.state.newEventPhotoImage ? "my-4" : "d-none")}>

        {newEventPhotoElem}

        <form className="mt-3">
          <input
            onChange={this.handlePhotoFileInputChange}
            ref={this.photoFileInputRef}
            className="d-none"
            type="file"
          />

          <div className="form-group">
            <label className="mb-0" htmlFor="title">Title</label>
            <input
              value={this.state.newEventFormData.title}
              onChange={this.handleTitleChange}
              placeholder="Choose a post title (optional)"
              className="form-control"
              type="text"
            />
          </div>

          <div className="form-group">
            <label className="mb-0" htmlFor="body">Details</label>
            <textarea
              value={this.state.newEventFormData.body}
              onChange={this.handleBodyChange}
              placeholder="Describe the current environment (optional)"
              className="form-control"
              rows="2">
            </textarea>
          </div>

          <div className="form-group">
            <label className="mb-0" htmlFor="name">Name</label>
            <input
              value={this.state.newEventFormData.name_creator_provided}
              onChange={this.handleNameChange}
              placeholder="Your name, or nickname (optional)"
              className="form-control"
              type="text"
            />
          </div>
        </form>

        {submitButtonElem}

        <span className="p-2 text-danger font-weight-light float-right" onClick={this.cancelNewEventCreation}>Cancel</span>
        <div className="d-none">{geoElem}</div>

        <hr className="my-4"/>
      </div>
  

      </>
    )
  }

}

export default NewEvent;
