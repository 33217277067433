import React from 'react';
import Config from './Config.js';
import './Event.css';

const ANIMATION_SPEED = 1000;


class EventSatelliteImages extends React.Component {

  constructor(props) {
    super(props);
    this.shouldAnimate = false;
    this.shouldAnimateBackwards = false;
    this.state = {
      animationCount: null
    }
  }

  componentDidMount() {
    const maxCount = this.props.data.satellite_animation_count;
    this.setState({animationCount: maxCount});
    if (this.shouldAnimate) {
      this.animationStart();
    }
    this.props.onLoadingComplete(); // TODO: load all sat images.
  }

  componentWillUnmount() {
    this.animationStop();
  }

  animateStep() {
    const maxCount = this.props.data.satellite_animation_count;
    const currCount = this.state.animationCount;
    let newCount;
    if (this.shouldAnimateBackwards) {
      newCount = (currCount > 1) ? currCount - 1 : maxCount;
    } else {
      newCount = (currCount < maxCount) ? currCount + 1 : 1;
    }
    this.setState((state, props) => ({animationCount: newCount}));
  }

  handleImageClick = (evt) => {
    if (this.props.data.satellite_animation_count === 0) {
      return
    }

    const targetRect = evt.target.getBoundingClientRect();
    const elemWidth = targetRect.width;
    const xPos = evt.nativeEvent.offsetX;
    if (xPos < (elemWidth/3)) {
      this.animationManualStep(true);
    } else if (xPos > (2*elemWidth/3)) {
      this.animationManualStep(false);
    } else {
      this.shouldAnimateBackwards = false;
      this.animationToggle();
    }
  }

  animationToggle() {
    if (this.animateIntervalID) {
      this.animationStop();
    } else {
      this.animateStep();
      this.animationStart();
    }
  }

  animationManualStep(goBackwards) {
    this.animationStop();
    this.shouldAnimateBackwards = goBackwards;
    this.animateStep();
  }

  animationStart() {
    this.animateIntervalID = setInterval(() => {
      this.animateStep()}, ANIMATION_SPEED);
  }

  animationStop() {
    clearInterval(this.animateIntervalID);
    this.animateIntervalID = null;
  }

  getCurrentImageUrl = (animationCount) => {
    const rootDomain = Config.S3_SITE_ROOT;
    const baseUrl = '/event/' + this.props.data.id + '/';
    if (animationCount && animationCount > 0) {
      return `${rootDomain}${baseUrl}satellite_animation_${animationCount}.jpg`;
    } else {
      return `${rootDomain}${baseUrl}satellite_orig.gif`;
    }
  }


  getSatelliteImageHost = () => {
    try {
      return (new URL(this.props.data.satellite_image_url)).hostname;
    } catch(e) { return ''; }
  }


  render() {
    if (this.state.animationCount != null) {
      const animationCountText = this.state.animationCount > 0 ? `#${this.state.animationCount}` : '';
      return (
        <div className="event-satellite h-100 p-3 shadow-sm border border-light">
          <img src={this.getCurrentImageUrl(this.state.animationCount)}
               onClick={this.handleImageClick}
               className="mb-2 border border-secondary"
               alt="Clouds at event.ISODate() from satellite above"/>
          <div>Satellite image {animationCountText} (<a href="{this.props.data.satellite_image_url}" target="_blank" title="Satellite image source">{this.getSatelliteImageHost()}</a>)</div>
        </div>
      );
    } else {
      return (
        <div className="text-center">Loading...</div>
      );
    }
  }
}

export default EventSatelliteImages;
